const Input = ({type, name, label, value, onChange}) => {
    return <div className="form-item form-item-text">
        <div className="form-label"><label htmlFor={name}>{label}</label></div>
        <div className={'form-' + type}>
            <input id={name} name={name} type={type} value={value} onChange={onChange} />
        </div>
    </div>;
};

export default Input;
