const Select = ({name, label, options, value, onChange}) => {
    const getOptions = (options) => {
        let items = [];
        for (let option in options) {
            if (typeof options[option] === 'object') {
                let groupItems = [];
                for (let groupOption in options[option]) {
                    if (typeof options[option][groupOption] !== 'object') {
                        groupItems.push(<option value={groupOption} key={groupOption}>{options[option][groupOption]}</option>);
                    }
                }
                items.push(<optgroup label={option} key={option}>{groupItems}</optgroup>);
            }
            else {
                items.push(<option value={option} key={option}>{options[option]}</option>);
            }
        }

        return items;
    };
    const items = getOptions(options);

    return <div className="form-item form-item-select">
        <div className="form-label"><label htmlFor={name}>{label}</label></div>
        <div className="form-select">
            <select id={name} name={name} defaultValue={value} onChange={onChange}>
                {items}
            </select>
        </div>
    </div>;
};

export default Select;
