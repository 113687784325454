import {useEffect, useState} from "react";
import Header from "./components/Header";
import Wrapper from "./components/Wrapper";
import InputForm from "./components/InputForm";
import Result from "./components/Result";
import AddToHome from "./components/AddToHome";
import noImage from "./images/no-image.png";
import vehicles from "./vehicles.json";

const lsCache = require('localstorage-ttl');

const App = () => {
    const [showResult, setShowResult] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [inputValues, setInputValues] = useState({
        'reg': '',
        'mileage': '',
        'vehicle': '_default',
        'cost': {
            'electricity': 30, // Pence per Kilowatt Hour
            'fuel': 1.45 // Pounds per litre
        },
    });
    const [currentCar, setCurrentCar] = useState({
        'name': '',
        'image': noImage,
        'ev': false,
        'mpg': '',
        'battery': {},
        'co2': '',
    });

    useEffect(() => {
        const cid = 'vmr_lookup_' + inputValues.reg.toLowerCase().split(' ').join('');
        let cache = lsCache.get(cid);
        if (cache && typeof cache === 'object') {
            cache.data = currentCar;
            lsCache.set(cid, cache, 24 * 3600 * 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCar]);

    const [newCar, setNewCar] = useState({
        'name': '',
        'image': noImage,
        'ev': true,
        'mpg': '',
        'battery': {},
        'co2': 0,
    });

    const setNewCarData = (vehicle) => {
        if (vehicles.hasOwnProperty(vehicle)) {
            const newCarData = vehicles[vehicle];
            newCar.name = newCarData.name;
            if (newCarData.image.startsWith('data:image')) {
                newCar.image = newCarData.image;
            } else {
                newCar.image = window.location.pathname.replace(/\/$/, '') + '/cars/' + newCarData.image;
            }
            newCar.co2 = newCarData.co2;
            newCar.battery = newCarData.battery;
            setNewCar(newCar);
        }
    };

    useEffect(() => {
        for (let i in vehicles) {
            // Pre-load images.
            if (!vehicles[i].image.startsWith('data:image')) {
                const imageUrl = window.location.pathname.replace(/\/$/, '') + '/cars/' + vehicles[i].image;
                fetch(imageUrl)
                    .then((res) => res.blob())
                    .then((blob) => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            vehicles[i].image = reader.result;
                        };
                        reader.readAsDataURL(blob);
                    });
            }
        }

        setNewCarData(inputValues.vehicle);
    });

    const [apiLoaded, setApiLoaded] = useState(false);
    const getApiData = (success) => {
        const cid = 'vmr_lookup_' + inputValues.reg.toLowerCase().split(' ').join('');
        let cache = lsCache.get(cid);
        if (cache) {
            if (cache.status === 'success') {
                setErrorMessage('');
                for (let prop in cache.data) {
                    currentCar[prop] = cache.data[prop];
                }
                setCurrentCar(currentCar);
                setApiLoaded(true);
                if (typeof success === 'function') {
                    success();
                }
            } else {
                setErrorMessage(cache.statusMessage);
            }
        } else {
            setApiLoaded(false);
            if (inputValues.reg.length === 0) {
                setErrorMessage('Please enter registration number');
            } else {
                setErrorMessage('');
                const url = window.location.pathname.replace(/\/$/) + '/api?reg=' + inputValues.reg;
                fetch(url)
                    .then((res) => {
                        res.json().then(
                            (result) => {
                                if (res.status !== 200) {
                                    if (result.statusMessage) {
                                        setErrorMessage(result.statusMessage);
                                    }
                                    else {
                                        setErrorMessage('Unknown error');
                                    }
                                } else {
                                    if (result.status === 'success') {
                                        currentCar.costPerYear = null;
                                        currentCar.costPerMonth = null;
                                        currentCar.emissionPerYear = null;
                                        currentCar.emissionPerMonth = null;
                                        for (let prop in result.data) {
                                            currentCar[prop] = result.data[prop];

                                            if (prop === 'image' && (!result.data[prop] || result.data[prop].length === 0)) {
                                                currentCar[prop] = noImage;
                                            }
                                        }
                                        setCurrentCar(currentCar);
                                        setApiLoaded(true);
                                    } else {
                                        setErrorMessage(result.statusMessage);
                                    }

                                    cache = {
                                        status: result.status,
                                        statusCode: result.statusCode,
                                        statusMessage: result.statusMessage ? result.statusMessage : '',
                                        data: currentCar
                                    };
                                    lsCache.set(cid, cache, 24 * 3600 * 1000);
                                    if (typeof success === 'function') {
                                        success();
                                    }
                                }
                            },
                            (error) => {
                                setErrorMessage(error.message);
                                console.log(error);
                            }
                        );
                    })
            }
        }
    };

    const calculate = () => {
        if (typeof inputValues.mileage !== 'number') {
            setShowResult(false);
        } else {
            const currentCarCalc = {...currentCar};
            const newCarCalc = {...newCar};

            [currentCarCalc, newCarCalc].forEach((car) => {
                if (typeof car.mpg === 'number') {
                    car.costPerYear = inputValues.mileage / car.mpg * (inputValues.cost.fuel / 0.212);
                    car.costPerMonth = car.costPerYear / 12;
                } else if (typeof car.battery.range === 'number' && typeof car.battery.size === 'number') {
                    car.costPerYear = (inputValues.mileage / car.battery.range) * (inputValues.cost.electricity / 100) * car.battery.size;
                    car.costPerMonth = car.costPerYear / 12;
                }

                if (typeof car.costPerYear === 'number') {
                    car.costPerYear = Math.round(car.costPerYear * 100) / 100;
                    car.costPerMonth = Math.round(car.costPerMonth * 100) / 100;
                }

                if (typeof car.co2 === 'number') {
                    car.emissionPerYear = Math.round(car.co2 * inputValues.mileage * 0.62 / 10) / 100;
                    car.emissionPerMonth = Math.round(car.emissionPerYear / 12 * 100) / 100;
                }
            });
            setCurrentCar(currentCarCalc);
            setNewCar(newCarCalc);
            setShowResult(true);
        }
    };

    const handleChange = (event, name) => {
        let newValues = {...inputValues};
        newValues[name] = event.target.value;

        if (name === 'mileage' && newValues[name]) {
            newValues[name] = Number(newValues[name]);
            if (newValues[name] < 0) {
                newValues[name] = 0;
            }
        }
        setInputValues(newValues);

        if (name === 'vehicle' && vehicles.hasOwnProperty(newValues.vehicle)) {
            setNewCarData(newValues.vehicle);
            if (apiLoaded) {
                calculate();
            }
        }
    };

    useEffect(() => {
        if (apiLoaded) {
            calculate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValues]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (inputValues.mileage > 0) {
            getApiData(() => {
                calculate();
            });
        } else {
            setErrorMessage('Please enter mileage');
        }
    };

    const handleReset = (event) => {
        event.preventDefault();
        setApiLoaded(false);
        setShowResult(false);
    };

    let vehicleOptions = {};
    for (let i in vehicles) {
        const name = vehicles[i].short_name || vehicles[i].name;
        if (vehicles[i].make) {
            vehicleOptions[vehicles[i].make] = vehicleOptions[vehicles[i].make] || {};
            vehicleOptions[vehicles[i].make][i] = name;
        }
        else {
            vehicleOptions[i] = name;
        }
    }

    return (
        <div className="app">
            <Header/>
            <Wrapper>
                {!showResult || !apiLoaded
                    ? <InputForm values={inputValues} vehicles={vehicleOptions} handleChange={handleChange}
                                 handleSubmit={handleSubmit} error={errorMessage}/>
                    : <Result currentCar={currentCar} newCar={newCar} newCarId={inputValues.vehicle} vehicles={vehicleOptions}
                              handleChange={handleChange} handleReset={handleReset}/>
                }
            </Wrapper>
            <AddToHome/>
        </div>
    );
}

export default App;
