import Select from "./Select";

const Car = (props) => {
    let className = ['car'];
    if (props.className) {
        className.push(props.className);
    }
    if (props.ev) {
        className.push('car--ev');
    }

    let output = {};
    ['costPerYear', 'costPerMonth', 'emissionPerYear', 'emissionPerMonth'].forEach((prop) => {
        if (typeof props[prop] === 'number') {
            if (['costPerYear', 'costPerMonth'].includes(prop)) {
                output[prop] = props[prop].toLocaleString('en-GB', {
                    style: 'currency',
                    currency: 'GBP'
                });
            }
            else {
                output[prop] = props[prop].toLocaleString('en-GB');
            }
        }
        else {
            output[prop] = 'N/A';
        }
    });

    return <div className={className.join(' ')}>
        <div className="car__name">
            {props.options
                ? <Select name="vehicle" label="Select EV" value={props.vehicle} options={props.options} onChange={event => props.handleChange(event, 'vehicle')} />
                : props.name
            }
        </div>
        <div className="car__image">
            <img src={props.image} alt={props.name} />
        </div>
        <div className="car__costs">
            <h3>Cost in {props.ev ? 'Electricity' : 'Fuel'}</h3>
            <div className="car__cost car__cost--per-month">{output.costPerMonth} <span>/ month</span></div>
            <div className="car__cost car__cost--per-year">{output.costPerYear} <span>/ year</span></div>
        </div>
        <div className="car__emissions">
            <h3>CO<sub>2</sub> Emissions</h3>
            <div className="car__emission car__emission--per-month">{output.emissionPerMonth}kg <span>/ month</span></div>
            <div className="car__emission car__emission--per-year">{output.emissionPerYear}kg <span>/ year</span></div>
        </div>
    </div>
};

export default Car;
