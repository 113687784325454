import {useState, useEffect, useRef} from "react";
import titleImage from "../images/results-title.svg";
import Car from "./Car";
import TotalResult from "./TotalResult";

const Result = ({currentCar, newCar, newCarId, vehicles, handleChange, handleReset}) => {
    let savings = {};

    const [termsOpen, setTermsOpen] = useState(window.location.hash === '#terms');
    const termsRef = useRef(null);
    const toggleOpen = (event) => {
        event.preventDefault();
        setTermsOpen(!termsOpen);
    };
    useEffect(() => {
        if (termsOpen) {
            termsRef.current.scrollIntoView();
        }
    }, [termsOpen]);

    ['costPerMonth', 'costPerYear', 'emissionPerMonth', 'emissionPerYear'].forEach((prop) => {
        if (typeof currentCar[prop] === 'number' && typeof newCar[prop] === 'number') {
            savings[prop] = Math.round((currentCar[prop] - newCar[prop]) * 100) / 100;

            if (['costPerYear', 'costPerMonth'].includes(prop)) {
                savings[prop] = savings[prop].toLocaleString('en-GB', {
                    style: 'currency',
                    currency: 'GBP'
                });
            }
            else {
                savings[prop] = savings[prop].toLocaleString('en-GB');
            }
        }
        else {
            savings[prop] = 'N/A';
        }
    });

    return <div className="screen results">
        <div className="screen__header results__header">
            <img className="title" src={titleImage} alt="The quiet revolution will be calculated" width="552" height="159" />
        </div>
        <div className="screen__content results__content">
            <Car {...currentCar} className="car--current"/>
            <Car {...newCar} vehicle={newCarId} options={vehicles} handleChange={handleChange} className="car--new" />
            <TotalResult {...savings} />
        </div>
        <form className="results__form" onReset={handleReset}>
            <div className="form-actions">
                <button type="reset" className="button">Restart</button>
                <a href="https://www.wessexgarages.com/electric-vehicles/" target="_blank" rel="noreferrer" className="button button--outline">View Full EV Range</a>
            </div>
        </form>
        <div className={`terms ${termsOpen ? "open" : ""}`}>
            <a href="#terms" className="handle" onClick={toggleOpen}>Terms & Conditions</a>
            <div id="terms" className="terms__inner" ref={termsRef}>
                <p>
                    Vehicle images are for illustration purposes only and may not be to full UK specification.
                    Actual charging speed & charging time may be influenced by the battery temperature and exterior weather conditions.
                    Vehicle ranges were determined according to the standardized EU measurement procedure (WLTP) based on battery pack sizes, rear wheel drive and standard wheels.
                    The individual driving style and other factors, such as speed, outside temperature, topography, and the use of electricity-consuming devices/units, have an influence on the real-life range and can possibly reduce it.
                </p>
                <p>
                    The cost per fuel (cost per litre) is based on UK petrol prices which can be viewed <a href="https://www.theaa.com/driving-advice/driving-costs/fuel-prices">here</a>.
                    The cost of electricity (pKwH) is based on UK Average prices which can be viewed <a href="https://www.nimblefins.co.uk/average-cost-electricity-kwh-uk">here</a>.
                </p>
            </div>
        </div>
    </div>
};

export default Result;
