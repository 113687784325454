const TotalResult = ({costPerMonth, costPerYear, emissionPerMonth, emissionPerYear}) => {
    return <div className="total-result">
        <h2>Total Savings:</h2>
        <div className="total-result__savings">
            <div className="total-result__saving total-result__saving--per-month">{costPerMonth} <span>/ month</span></div>
            <div className="total-result__saving total-result__saving--per-year">{costPerYear} <span>/ year</span></div>
            <div className="total-result__emission total-result__emission--per-month">{emissionPerMonth}kg <span>CO<sub>2</sub> / month</span></div>
            <div className="total-result__emission total-result__emission--per-year">{emissionPerYear}kg <span>CO<sub>2</sub> / year</span></div>
        </div>
        <div className="total-result__extra">
            <h3>Other Savings Could Include:</h3>
            <ul>
                <li>Servicing Costs</li>
                <li>VED</li>
                <li>Clean Air Zones</li>
            </ul>
        </div>
    </div>
};

export default TotalResult;
