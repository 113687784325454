import Input from "./Input";
import Select from "./Select";
import titleImage from "../images/form-title.svg";

const InputForm = ({ values, vehicles, handleChange, handleSubmit, error }) => {
    return <div className="screen input-form">
        <div className="screen__header input-form__header">
            <img className="title" src={titleImage} alt="The quiet revolution will be calculated" width="518" height="173" />
            <div className="intro">See what you can save by switching to an EV vehicle with our handy calculator</div>
        </div>
        <form className="input-form__form" onSubmit={handleSubmit}>
            {typeof error === 'string' && error.length > 0 &&
                <div className="input-form__error">{error}</div>
            }
            <fieldset>
                <legend>Your Current Vehicle</legend>
                <Input type="text" name="reg" label="Registration" value={values.reg} onChange={event => handleChange(event, 'reg')} />
                <Input type="number" name="mileage" label="Mileage per year" value={values.mileage} onChange={event => handleChange(event, 'mileage')} />
            </fieldset>
            <fieldset>
                <legend>Your New Vehicle</legend>
                <Select name="vehicle" label="Select EV" value={values.vehicle} options={vehicles} onChange={event => handleChange(event, 'vehicle')} />
            </fieldset>
            <div className="form-actions">
                <button type="submit">Calculate</button>
            </div>
        </form>
    </div>
};

export default InputForm;
