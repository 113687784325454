import React, {useEffect, useState} from "react";
import {ReactComponent as Icon} from "../images/icon-home.svg";
import {ReactComponent as IconShare} from "../images/icon-share.svg";

const AddToHome = () => {
    const [display, setDisplay] = useState(false);
    const [displayPopup, setDisplayPopup] = useState(false);

    const parentHasClass = (element, classname) => {
        if (!element.classList) return false;
        if (element.classList.contains(classname)) return true;
        return element.parentNode && parentHasClass(element.parentNode, classname);
    }

    const isIos = () => {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    }

    const isIpad = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /ipad/.test(userAgent)
            || ['iPad Simulator', 'iPad'].includes(navigator.platform)
            || (!/iphone|ipod/.test(userAgent) && navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    }

    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    useEffect(() => {
        const installPromptListener = (e) => {
            e.preventDefault();
            window.deferredPrompt = e;
            setDisplay(true);
        };
        window.addEventListener('beforeinstallprompt', installPromptListener);

        const clickListener = (event) => {
            if (!parentHasClass(event.target, 'add-to-home__popup')
                && !parentHasClass(event.target, 'add-to-home')) {
                setDisplayPopup(false);
            }
        };
        document.body.addEventListener('click', clickListener);

        if (isIos() && !isInStandaloneMode()) {
            setDisplay(true);
        }

        return () => {
            window.removeEventListener('beforeinstallprompt', installPromptListener);
            document.body.removeEventListener('click', clickListener);
        };
    });

    const onClick = (event) => {
        if (typeof deferredPrompt === 'object') {
            setDisplay(false);
            window.deferredPrompt.prompt();
            window.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                window.deferredPrompt = null;
            });
        }
        else if (isIos()) {
            if (displayPopup) {
                setDisplayPopup(false);
            }
            else {
                setDisplayPopup(true);
            }
        }
    }

    return (
        <div>
            {display && <button onClick={onClick} className="add-to-home button--icon"><Icon/></button>}
            {displayPopup && <div className={`add-to-home__popup ${isIpad() ? "ipad" : ""}`}>To install this webapp tap Share <IconShare/> and then "Add to Home Screen".</div>}
        </div>
    );
};

export default AddToHome;
